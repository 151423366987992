import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAResource } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddResourceSlidingMenu(props) {
  const [ResourceTitle, setResourceTitle] = useState("");
  const [ResourceDescription, setResourceDescription] = useState("");

  const reset = () => {
    setResourceTitle("");
    setResourceDescription("");
  };

  const addNewResourceHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PostAResource(ResourceTitle, ResourceDescription);
    if (status === 200) {
      reset();
      toast.success("Resource added Successfully");
      props.onClickCloseHandler();
      props.history.push("/Resources");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">Add new Resource</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Resource Title</div>
        <input className="SlidingMenuTextBox" id="ResourceTitle" value={ResourceTitle} onChange={(e) => setResourceTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">The name of the Resource should be written here. This name will later be used for defined staff and can be assigned to them.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Resource Description</div>
        <textarea className="SlidingMenuTextArea" id="ResourceDescription" value={ResourceDescription} onChange={(e) => setResourceDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">A brief description about the Resource</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>
          <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addNewResourceHandler(e)}>
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddResourceSlidingMenu);
