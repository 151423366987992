import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { isLoggedIn, loginAction } from "../../actions";
import { loginUser } from "../../Services/userServices";
import "./LoginPage.css";
import CheckLogin from "./../../utils/checkLogin";
import decodeToken from "../../utils/decodeToken";

function LoginPage({ history }) {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setLoading] = useState(false);
  const [, forceUpdate] = useState();

  const reset = () => {
    setUsername("");
    setPassword("");
  };

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Please fill this textbox",
      },
      element: (message) => <div style={{ color: "#ffffff", fontSize: "14px", fontWeight: "bolder" }}>{message}</div>,
    })
  );

  const login = async (e) => {
    e.preventDefault();
    try {
      if (validator.current.allValid()) {
        setLoading("true");

        const { data, status } = await loginUser(username, password);
        if (status === 200) {
          toast.success("You are successfully logged in");
          const decodedToken = await decodeToken(data.token);
          const userDetails = { decodedToken, userData: data };
          localStorage.setItem("residentialManagement", JSON.stringify(userDetails));
          dispatch(loginAction(userDetails));
          dispatch(isLoggedIn(true));

          setLoading(false);

          window.location.reload();
          history.replace("/LandingPage");
          reset();
        }
      } else {
        validator.current.showMessages("username");
        forceUpdate(1);
      }
    } catch (ex) {
      toast.error(ex.response.data.ErrorDescription);
      setLoading(false);
    }
  };
  if (CheckLogin()) history.replace("/LandingPage");

  return (
    <div className="FullscrennBackground BackgroundLightToWhiteGradient">
      <div className="Logincontainer VerticallyAndHorizontallyCentered ColorWhiteColor HorizontallyCenteredText">
        <img className="LogInPicture" src="/images/Pardiklogolandscape.png" alt="" />
        <div className="LoginPageHeader">خوش آمدید</div>
        <div className="LoginSubHeader">ورود به پنل کاربری</div>
        <div className="LoginTextBoxContainer">
          <div className="LoginTextBoxBackground"></div>
          <span className="icon icon-user IconCircleBackground"></span>
          <input className="LoginTextBox" placeholder="نام کاربری" type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          {validator.current.message("username", username, "required")}
        </div>
        <div className="LoginTextBoxContainer">
          <div className="LoginTextBoxBackground"></div>
          <span className="icon icon-lockedFeature IconCircleBackground"></span>
          <input className="LoginTextBox" placeholder="رمز عبور" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          {validator.current.message("password", password, "required")}
        </div>
        {/* <NavLink exact className="BackgorundPrimaryColor button" to="/LandingPage" activeClassName="HeaderNavigation-Active">
          Login
        </NavLink> */}
        <div className="LoginTextBoxContainer LoginButtonContainerMargin">
          <button className="BackgorundPrimaryColor LoginButton" type="submit" onClick={(e) => login(e)}>
            ورود
          </button>
        </div>
      </div>
    </div>
  );
}
export default withRouter(LoginPage);
