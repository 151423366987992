import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./Layout.css";
import "./LayoutRtl.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import CheckLogin from "../../utils/checkLogin";
import { BrowserView, MobileView } from "react-device-detect";

function LayoutResidents(props) {

  const [residentialManagmentOpen, setResidentialManagmentOpen] = useState();
  const [managmentOpen, setmanagmentOpen] = useState();
  const [provisionOpen, setprovisionOpen] = useState();
  const [mirrorSectionOpen, setMirrorSectionOpen] = useState();
  const [showMenu, setShowMenu] = useState(false);

  if (!CheckLogin()) props.history.replace("/");

  const openResidentialManagment = () => {
    setResidentialManagmentOpen(!residentialManagmentOpen);
  };
  const openManagment = () => {
    setmanagmentOpen(!managmentOpen);
  };
  const openBuildingManagment = () => {
    setprovisionOpen(!provisionOpen);
  };
  const openMirrorSection = () => {
    setMirrorSectionOpen(!mirrorSectionOpen);
  };

  const ShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      <BrowserView>
        <div className="LayoutContainer">
          <div className="HeaderandContentContainert RtlDirection">
            <div className="LayoutHeader VerticallyCenteredObject">
              <div className="HeaderBarCss">
                <div className="DisplayFlex">{/* <Breadcrumbs title={props.location.state ? props.location.state.id.title : null} /> */}</div>
                <div className="HeaderBarIconsContainer">
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <NavLink exact to="/Logout">
                      <span className="icon-exit"></span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="LayoutContent BackgroundLightToWhiteGradient">{props.children}</div>
          </div>
          <div className="SideBar">
            <div className="HotelInformationContainer">
              <img className="SidebarTitleImage" src="/images/ic_logo.png" alt="" />
              <div className="SidebarTitle">امید دریانیان</div>

            </div>

            <List component="div" disablePadding>
              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/ResidentProfile" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="اطلاعات کاربری" sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>
              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/ResidentMessages" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="پیام ها" sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>

              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="رزرو سرویس" sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>
              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="کیف پول" sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>
              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="بدهی/شارژ  " sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>
              <ListItemButton className="MuiSubListCss">
                <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active">
                  <ListItemText primary="لیست تراکنش ها" sx={{ pl: 8 }} />
                </NavLink>
              </ListItemButton>
            </List>

            <div className="LayoutSideBarFooterContainer">
              <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                <NavLink exact to="/Settings">
                  <ListItemButton className="MuiListCssSelected">
                    <ListItemIcon>
                      <span className="icon-settings ColorWhiteColor LayoutListIcon"></span>
                    </ListItemIcon>

                    <ListItemText primary="تنظیمات" sx={{ pl: 1 }} />
                  </ListItemButton>
                </NavLink>
              </List>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="DeviceLayoutContainer">
          <div className="DeviceHeaderandContentContainert RtlDirection">
            <div className="LayoutHeader VerticallyCenteredObject">
              <div className="DeviceHeaderBarCss">
                <div className="HeaderBarIconsContainer">
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                  <div className="HeaderBarIcons">
                    <div className="NotificationAmountContainer">5</div>
                    <span className="icon-notification "></span>
                  </div>
                </div>
                <div>
                  <div className="MenuCloseButton" onClick={ShowMenu}>
                    <span className="icon-menu"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="LayoutContent BackgroundLightToWhiteGradient">{props.children}</div>
          </div>
          <div className={showMenu ? "SideBarOpen" : "SideBarClosed"}>
            <div className="HeaderPlacings">
              <div className="HotelInformationContainer">
                <img className="DeviceSidebarTitleImage" src="/images/ic_logo.png" alt="" />
                <div className="SidebarTitle">برج سپهر</div>
              </div>
              <div className="MenuCloseButton" onClick={ShowMenu}>
                <span className="icon-closeX ColorBlackColor"></span>
              </div>
            </div>

            <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
              {residentialManagmentOpen ? (
                <ListItemButton className="MuiListCssSelected" onClick={openResidentialManagment}>
                  <ListItemIcon>
                    <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدریت کاربران" />
                  <span className="icon-DropUpArrow ColorWhiteColor"></span>
                </ListItemButton>
              ) : (
                <ListItemButton onClick={openResidentialManagment}>
                  <ListItemIcon>
                    <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدریت کاربران" />
                  <span className="icon-DropDownArrow ColorBlackColor"></span>
                </ListItemButton>
              )}

              <Collapse in={residentialManagmentOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/Residents" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="کاربران" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                  {/* <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Roles" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="نقش ها" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Resources" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="منابع" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton> */}
                </List>
              </Collapse>
            </List>

            <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
              {provisionOpen ? (
                <ListItemButton className="MuiListCssSelected" onClick={openBuildingManagment}>
                  <ListItemIcon>
                    <span className="icon-provision ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدریت ساختمان" />
                  <span className="icon-DropUpArrow ColorWhiteColor"></span>
                </ListItemButton>
              ) : (
                <ListItemButton onClick={openBuildingManagment}>
                  <ListItemIcon>
                    <span className="icon-provision ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدریت ساختمان" />
                  <span className="icon-DropDownArrow ColorBlackColor"></span>
                </ListItemButton>
              )}

              <Collapse in={provisionOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/ServiceTypes" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="سرویس ها" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/ServiceRules" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="قوانین سرویس ها" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/SpecialRules" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="قوانین روزهای خاص" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
              {mirrorSectionOpen ? (
                <ListItemButton className="MuiListCssSelected" onClick={openMirrorSection}>
                  <ListItemIcon>
                    <span className="icon-mirror ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدیریت آینه" />
                  <span className="icon-DropUpArrow ColorWhiteColor"></span>
                </ListItemButton>
              ) : (
                <ListItemButton onClick={openMirrorSection}>
                  <ListItemIcon>
                    <span className="icon-mirror ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدیریت آینه" />
                  <span className="icon-DropDownArrow ColorBlackColor"></span>
                </ListItemButton>
              )}

              <Collapse in={mirrorSectionOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/Announcements" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="اطلاعیه ها" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>

                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/MirrorCompliments" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="خوشامدگویی" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
              {managmentOpen ? (
                <ListItemButton className="MuiListCssSelected" onClick={openManagment}>
                  <ListItemIcon>
                    <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدیریت مشتریان" />
                  <span className="icon-DropUpArrow ColorWhiteColor"></span>
                </ListItemButton>
              ) : (
                <ListItemButton onClick={openManagment}>
                  <ListItemIcon>
                    <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>
                  <ListItemText primary="مدیریت مشتریان" />
                  <span className="icon-DropDownArrow ColorBlackColor"></span>
                </ListItemButton>
              )}

              <Collapse in={managmentOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/Staffs" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="مدریران ساختمان" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/Roles" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="نقش ها" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                  <ListItemButton className="MuiSubListCss">
                    <NavLink exact className="NavLinkWidth" to="/Resources" activeClassName="HeaderNavigation-Active" onClick={ShowMenu}>
                      <ListItemText primary="منابع" sx={{ pl: 8 }} />
                    </NavLink>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            <div className="DeviceLayoutSideBarFooterContainer">
              <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
                <NavLink exact to="/Logout">
                  <ListItemButton className="MuiListCssSelected">
                    <ListItemIcon>
                      <span className="icon-exit ColorWhiteColor LayoutListIcon"></span>
                    </ListItemIcon>

                    <ListItemText primary="خروج" sx={{ pl: 1 }} />
                  </ListItemButton>
                </NavLink>
              </List>
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  );
}

export default withRouter(LayoutResidents);
