import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostARole } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddRoleSlidingMenu(props) {
  const [roleTitle, setRoleTitle] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  const reset = () => {
    setRoleTitle("");
    setRoleDescription("");
  };

  const addNewRoleHandler = async (e) => {
    e.preventDefault();
    const { data, status } = await PostARole(roleTitle, roleDescription);
    if (status === 200) {
      reset();
      toast.success("Role added Successfully");
      props.onClickCloseHandler();
      props.history.push("/Roles");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">Add new Role</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Role Title</div>
        <input className="SlidingMenuTextBox" id="roleTitle" value={roleTitle} onChange={(e) => setRoleTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">The name of the role should be written here. This name will later be used for defined staff and can be assigned to them.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">Role Description</div>
        <textarea className="SlidingMenuTextArea" id="roleDescription" value={roleDescription} onChange={(e) => setRoleDescription(e.target.value)} />
        <div className="SlidingMenuMoreDetail">A brief description about the role</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>
          <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addNewRoleHandler(e)}>
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddRoleSlidingMenu);
