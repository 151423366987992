import React, { useState, useEffect } from "react";
import Loading from "../../../components/ui/loading/Loading";
import { withRouter } from "react-router-dom";
import { getResidentById, PutAResident } from "../../../Services/managementServices";
import { toast } from "react-toastify";

function EditResident({ history }) {
  const selectedCheckBox = history.location.state.id;

  const [residentUsername, setResidentUsername] = useState("");
  const [residentPasswrod, setResidentPassword] = useState("");
  const [residentRepeatPassword, setResidentRepeatPassword] = useState("");
  const [residentFullName, setResidentFullName] = useState("");
  const [residentMobile, setResidentMobile] = useState("");

  useEffect(() => {
    fillStates();
  }, []);

  const fillStates = async () => {
    const { data, status } = await getResidentById(selectedCheckBox);
    if (status === 200) {
      setResidentUsername(data.username);
      setResidentFullName(data.displayName);
      setResidentMobile(data.mobileNumber);
    }
  };

  const updateResidentHandler = async (e) => {
    e.preventDefault();

    try {
      const { status } = await PutAResident(selectedCheckBox, residentFullName, residentPasswrod, residentMobile);
      if (status === 200) {
        toast.success("تغییرات با موفقیت اعمال شد");
        fillStates();
      }
    } catch (err) {
      toast.error(err.response ? err.response.data.ErrorDescription : "Something went wrong!");
    }
  };

  return (
    <div className="ContentBody">
      <div className="BodyPadding">
        <div className="EditPageTwoColumnNoBG">
          <div className="EditPageTwoColumnItemWithBGWithScroll" id="style-3">
            <div className="EditContainerWithScroll">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نام کاربری</div>
                <input disabled className="SlidingMenuTextBox" value={residentUsername} id="residentUsername" />
                <div className="SlidingMenuMoreDetail">نام کاربری مختص واحد ثبت شده و غیر قابل تغییر.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">رمز عبور</div>
                <input className="SlidingMenuTextBox" id="StaffPassword" type="password" value={residentPasswrod ? residentPasswrod : null} onChange={(e) => setResidentPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">فقط در صورت تمایل در تغییر رمز غبور کاربر پر شود</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">تکرار رمز عبور</div>
                <input className="SlidingMenuTextBox" id="StaffRepeatPassword" type="password" value={residentRepeatPassword ? residentRepeatPassword : null} onChange={(e) => setResidentRepeatPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">تکرار رمز عبور جدید در صورت تمایل به تغییر رمز عبور</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">نام مورد نمایش</div>
                <input className="SlidingMenuTextBox" value={residentFullName} onChange={(e) => setResidentFullName(e.target.value)} />
                <div className="SlidingMenuMoreDetail">نام و نام خانوادگی کاربر اینجا نوشته شود</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">موبایل</div>
                <input className="SlidingMenuTextBox" value={residentMobile} onChange={(e) => setResidentMobile(e.target.value)} />
                <div className="SlidingMenuMoreDetail">شماره موبایل کاربر </div>
              </div>
              {residentPasswrod === residentRepeatPassword && residentFullName && residentMobile ? (
                <div className="FullWidthContainer">
                  <button type="sumbit" onClick={(e) => updateResidentHandler(e)} class="SaveButton" id="SaveButton">
                    <span class="icon-SaveButton"></span>ذخیره
                  </button>
                </div>
              ) : (
                <div className="FullWidthContainer">
                  <button type="sumbit" disabled class="SaveButton ButtonDisabled" id="SaveButton">
                    <span class="icon-SaveButton"></span>ذخیره
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="EditPageTwoColumnItem"></div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EditResident);
