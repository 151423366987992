import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../../components/ui/modal/modal.css";
import { DeleteAMirrorCompliment } from "../../../Services/mirrorServices";

export default function DeleteFoodModal(props) {
  const [deleteTextBox, setDeleteTextBox] = useState("");
  const deleteHandler = async (e, id) => {
    e.preventDefault();

    
    try {
      const { status } = await DeleteAMirrorCompliment(id);
      if (status === 200) {
        toast.success("Deleted Successfully");
        props.onClickCloseHandler();
        props.onClickRefresh();
      }
    } catch (ex) {
      toast.error(ex.response.data.ErrorDescription);
    }
  };

  return (
    <form className="ModalContainer">
      <div className="ModalHeader">
        <div className="ModalTitle">Delete x</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX ModalCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="ModalDetail">
          You are about to delete <span>{props.CurrentMirrorCompliments.title}</span> record and all it's inner records permanently. If you are certain of this action please type <span className="DeleteText">Delete</span> in the text box bellow to
          confirm this action.
        </div>
      </div>
      <div className="ModalFullWidthFlex">
        <div className="ModalQuarterWidthContainer">
          <input className="ModalTextBox" onChange={(e) => setDeleteTextBox(e.target.value)} id="FoodTitle" />
        </div>
        {deleteTextBox === "Delete" ? (
          <button onClick={(e) => deleteHandler(e, props.CurrentMirrorCompliments.id)} type="sumbit" className="ModalCancelbutton">
            Delete
          </button>
        ) : (
          <div className="ModalCancelbutton ModalButtonGrayedOut">Delete</div>
        )}
      </div>
    </form>
  );
}
