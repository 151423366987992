import React, { useEffect, useState } from "react";
import MirrorComplimentsGridTable from "./MirrorComplimentsGridTable";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import { actionGetAllMirrorCompliments } from "../../../actions";
import { useDispatch } from "react-redux";
import AddMirrorComplimentsSlidingMenu from "./AddMirrorComplimentsSlidingMenu";
import DeleteMirrorComplimentsModal from "./DeleteMirrorComplimentsModal";

export default function MirrorCompliments() {
  useEffect(() => {
    getMirrorCompliments();
  }, []);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(8);
  const [currentMirrorCompliments, setCurrentMirrorCompliments] = useState();


  const getMirrorCompliments = async (myPageIndex) => {
    dispatch(actionGetAllMirrorCompliments());
  };
  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
  const [slideMenuIsOpen, setSlideMenuIsOpen] = useState(false);
  const [grayoutHandler, setGrayoutHandler] = useState(false);
  const [deleteModalOpan, setDeleteModalOpan] = useState(false);

  function slideMenuOpenerHandler() {
    setSlideMenuIsOpen(true);
    setGrayoutHandler(true);
  }
  function slideMenuCloserHandler() {
    setSlideMenuIsOpen(false);
    setDeleteModalOpan(false);
    setGrayoutHandler(false);
  }
  function deleteModalOpenerHandler(item) {
    setCurrentMirrorCompliments(item);
    setDeleteModalOpan(true);
    setGrayoutHandler(true);
  }

  //⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑      Add Sliding Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        {grayoutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
        {slideMenuIsOpen && <AddMirrorComplimentsSlidingMenu onClickCloseHandler={slideMenuCloserHandler} />}
        {deleteModalOpan && <DeleteMirrorComplimentsModal CurrentMirrorCompliments={currentMirrorCompliments} onClickCloseHandler={slideMenuCloserHandler} onClickRefresh={getMirrorCompliments} />}
        <MirrorComplimentsGridTable PassPageSize={pageSize} onClickDeleteOpenHandler={deleteModalOpenerHandler} onClickOpenHandler={slideMenuOpenerHandler} />
      </div>
    </div>
  );
}
