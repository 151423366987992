import React, { useState } from "react";
import { toast } from "react-toastify";
import { PostAResident } from "../../../Services/managementServices";

export default function AddResidentsSlidingMenu(props) {
  const [residentUsername, setResidentUsername] = useState("");
  const [residentPasswrod, setResidentPasswrod] = useState("");
  const [residentDisplayName, setResidentDisplayName] = useState("");
  const [residentMobile, setResidentMobile] = useState("");

  const reset = () => {
    setResidentUsername("");
    setResidentPasswrod("");
    setResidentDisplayName("");
    setResidentMobile("");
  };

  const addResidentHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostAResident(residentUsername, residentPasswrod, residentDisplayName, residentMobile);
    if (status === 200) {
      reset();
      toast.success("کاربر باموفقیت ثبت شد");
      props.onClickCloseHandler();
      props.onClickRefresh();
      props.ResetPage();
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن سرویس جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام کاربری</div>
        <input className="SlidingMenuTextBox" id="residentUsername" value={residentUsername} onChange={(e) => setResidentUsername(e.target.value)} />
        <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">رمز عبور</div>
        <input type="password" className="SlidingMenuTextBox" id="residentPasswrod" value={residentPasswrod} onChange={(e) => setResidentPasswrod(e.target.value)} />
        <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">نام مورد نمایش</div>
        <input className="SlidingMenuTextBox" id="residentDisplayName" value={residentDisplayName} onChange={(e) => setResidentDisplayName(e.target.value)} />
        <div className="SlidingMenuMoreDetail">نامگذاری سرویس برای ارائه به مخاطب و مدیریت آن سرویس از طریق پنل مدیریت.</div>
      </div>
      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">موبایل</div>
        <input className="SlidingMenuTextBox" id="residentMobile" value={residentMobile} onChange={(e) => setResidentMobile(e.target.value)} />
        <div className="SlidingMenuMoreDetail">شماره موبایل کاربر تعریف شده.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <hr></hr>

        <div className="ButtonsContainer">
          {residentUsername && residentPasswrod && residentDisplayName && residentMobile ? (
            <button onClick={(e) => addResidentHandler(e)} type="sumbit" className="SaveButton">
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <div type="sumbit" className="SaveButton ButtonDisabled">
              <span className="icon-SaveButton "></span>
              ذخیره
            </div>
          )}

          <div onClick={props.onClickCloseHandler} className="CancelButton ">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}
