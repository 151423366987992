import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getMirrorComplimentById, PutAMirrorCompliment } from "../../../Services/mirrorServices";
import { toast } from "react-toastify";
import GrayedOut from "../../../components/ui/grayedout/GrayedOut";
import AddMirrorComplimentsItemSlidingMenu from "./AddMirrorComplimentsItemSlidingMenu";
import EditMirrorComplimentsItemSlidingMenu from "./EditMirrorComplimentsItemSlidingMenu";
import DeleteMirrorComplimentItemModal from "./DeleteMirrorComplimentItemModal";
import { PutAMirrorComplimentItem } from "./../../../Services/mirrorServices";

function EditMirrorCompliment(history) {
  const mySelectedMirrorComplimentId = history.location.state.id;
  const [myMirrorComplimentItems, setMyMirrorComplimentItems] = useState({});

  useEffect(() => {
    getSelectedMirrorCompliment();
  }, []);

  const getSelectedMirrorCompliment = async () => {
    const { status, data } = await getMirrorComplimentById(mySelectedMirrorComplimentId.id);
    if (status === 200) {
      setMyMirrorComplimentItems(data);
      console.log(data);
      setItemText(data.items[0].text);
      Datafiller(data);
    }
  };

  const [MirrorComplimentTitle, setMirrorComplimentTitle] = useState("");
  const [MirrorComplimentId, setMirrorComplimentId] = useState("");
  const [isActive, setisActive] = useState(false);
  const [itemText, setItemText] = useState("");

  const [expireDate, setExpireDate] = useState(false);

  const [selectedCheckBox, setSelectedCheckBox] = useState("");

  const [isSelected, setIsSelected] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [supportedLanguagescount, setSupportedLanguagescount] = useState();
  const [totalLanguagescount, settotalLanguagescount] = useState();

  const [addItemHandler, setAddItemHandler] = useState(false);
  const [editItemHandler, setEditItemHandler] = useState(false);
  const [deleteItemHandler, setDeleteItemHandler] = useState(false);
  const [grayOutHandler, setGrayOutHandler] = useState(false);

  const [expireDateCheckBoxStatus, setExpireDateCheckBoxStatus] = useState(false);

  const Datafiller = (MirrorCompliment) => {
    setMirrorComplimentId(MirrorCompliment.id);
    setMirrorComplimentTitle(MirrorCompliment.items[0].text);
    setisActive(MirrorCompliment.isActive);

    if (MirrorCompliment.expireDate !== null) {
      const fetchedDate = new Date(MirrorCompliment.expireDate);
      const convertedDate = fetchedDate.toLocaleDateString("en-CA");
      setExpireDate(convertedDate);
      expireDateCheckedHandler(true);
    } else {
      expireDateCheckedHandler(false);
      setExpireDate(null);
    }
    let SupportedLanguagescount = 0;

    let languagesId = [];
    let languagesItemsId = [];

    MirrorCompliment.languages.forEach((languages) => {
      MirrorCompliment.items.forEach((items, index) => {
        if (languages.id === items.languageId && languages.isActive) {
          SupportedLanguagescount++;
        }
      });
    });
    MirrorCompliment.languages.forEach((languages) => {
      languagesId.push(languages);
    });
    MirrorCompliment.items.forEach((items) => {
      languagesItemsId.push(items);
    });

    let availableLanguages = languagesId.filter((itemA) => {
      return !languagesItemsId.find((itemB) => {
        return itemA.id === itemB.languageId;
      });
    });

    setAvailableLanguages(availableLanguages);
    setSupportedLanguagescount(SupportedLanguagescount);
    settotalLanguagescount(availableLanguages.length + SupportedLanguagescount);
  };
  const expireDateCheckedHandler = (status) => {
    setExpireDateCheckBoxStatus(status);
  };
  const expireDateCheckeddataRemover = (status) => {
    if (status === false) {
      setExpireDate(null);
    }
  };

  function slideMenuCloserHandler() {
    setAddItemHandler(false);
    setEditItemHandler(false);
    setDeleteItemHandler(false);
    setGrayOutHandler(false);
  }

  const updateAnnouncementHandler = async (e) => {
    try {
      const { status } = await PutAMirrorCompliment(MirrorComplimentId, MirrorComplimentTitle, expireDate, isActive);
      if (status === 200) {
        const response = await PutAMirrorComplimentItem(MirrorComplimentId, myMirrorComplimentItems.items[0].languageId, itemText);
        if (response.status === 200) {
          toast.success("Updated Successfully");
        }
      } else {
        toast.warning("Somthing has occured!");
      }
    } catch (err) {
      toast.error(err.response.data.ErrorDescription);
    }
  };

  return (
    <>
      {addItemHandler && <AddMirrorComplimentsItemSlidingMenu onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedMirrorCompliment} MirrorComplimenttId={MirrorComplimentId} AvailableLanguages={availableLanguages} />}
      {deleteItemHandler && <DeleteMirrorComplimentItemModal onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedMirrorCompliment} MirrorComplimenttId={MirrorComplimentId} SelectedItem={selectedCheckBox} />}
      {editItemHandler && <EditMirrorComplimentsItemSlidingMenu onClickCloseHandler={slideMenuCloserHandler} ResetPage={getSelectedMirrorCompliment} MirrorComplimenttId={MirrorComplimentId} SelectedItem={selectedCheckBox} />}
      {grayOutHandler && <GrayedOut onClickCloseHandler={slideMenuCloserHandler} />}
      <div className="ContentBody">
        {myMirrorComplimentItems ? (
          <div className="BodyPadding">
            <div className="EditPageTwoColumnNoBG">
              <div className="EditPageTwoColumnItemWithBG">
                <div className="FullWidthFlex FullWidthFlexGap">
                  <div className="EditMenuFullWidthContainer">
                    <div className="TitleAndSliderCheck EditMenuFullWidthPadding">
                      <div className="EditItemTitle">وضعیت فعال بودن</div>
                      <label className="switch">
                        <input type="checkbox" onChange={(e) => setisActive(!isActive)} checked={isActive} />
                        <span className="switchslider round"></span>
                      </label>
                    </div>
                    <div className="SlidingMenuMoreDetail EditMenuFullWidthPadding">در صورت غیر فعال کردن این خوش آمد گویی، دیگر تا فعال کردن دوباره آن برای مخاطبین به نمایش گذاشته نمیشود.</div>

                    <div className="FullWidthContainer">
                      <div className="SlidingMenuItemTitle">عنوان</div>
                      <input className="SlidingMenuTextBox" id="ServiceTypesTitle" value={itemText || ""} onChange={(e) => setItemText(e.target.value)} />
                    </div>

                    <div className="TitleAndSliderCheck EditMenuFullWidthPadding">
                      <div className="EditItemTitle">تاریخ انقضا</div>
                      <label className="switch">
                        <input
                          name="DueDateCheckbox"
                          id="DueDateCheckbox"
                          type="checkbox"
                          onChange={(e) => (setExpireDateCheckBoxStatus(!expireDateCheckBoxStatus), expireDateCheckeddataRemover(!expireDateCheckBoxStatus))}
                          checked={expireDateCheckBoxStatus}
                        />

                        <span className="switchslider round"></span>
                      </label>
                    </div>
                    <div className="EditMenuFullWidthContainer EditMenuFullWidthPadding">
                      <div className="SlidingMenuItemTitle">زمان اتمام</div>
                      {!expireDateCheckBoxStatus ? (
                        <input disabled value={""} className="SlidingMenuTextBox" type="Date" id="DueDate" />
                      ) : (
                        <input onChange={(e) => setExpireDate(e.target.value)} value={expireDate} className="SlidingMenuTextBox" type="Date" id="DueDate" />
                      )}
                      <div className="SlidingMenuMoreDetail ">از تاریخ نتظیم شده به بعد این خوش آمد گویی به کاربران نشان داده نخواهد شد</div>
                    </div>
                  </div>
                </div>

                <div className=" FullWidthContainer">
                  <hr></hr>
                  <div className="ButtonsContainer">
                    {MirrorComplimentTitle && MirrorComplimentId !== null ? (
                      <button type="sumbit" className="SaveButton" id="SaveButton" onClick={(e) => updateAnnouncementHandler(e)}>
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    ) : (
                      <button type="sumbit" className="SaveButton ButtonDisabled" id="SaveButton">
                        <span className="icon-SaveButton"></span>
                        ذخیره
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
export default withRouter(EditMirrorCompliment);
