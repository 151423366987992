import http from "./httpServices";
import config from "./config.json";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Residents     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllResidents = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Resident/GetAll`, { pageIndex, pageSize });
};
export const getResidentById = (id) => {
  return http.get(`${config.PardikURL}/Resident/${id}`);
};
export const PostAResident = (username, password, displayName, mobile) => {
  return http.post(`${config.PardikURL}/Resident`, { username, password, displayName, mobile });
};
export const deleteResidentById = (residentId) => {
  return http.delete(`${config.PardikURL}/Resident/${residentId}`);
};
export const PutAssignRoleToResident = (id, roles) => {
  return http.put(`${config.PardikURL}/Resident/AssignRoleToUser/${id}`, { roleIds: roles });
};
export const PutAResident = (id, fullName, password, mobileNumber) => {
  return http.put(`${config.PardikURL}/Resident/UpdateResident/${id}`, { fullName, password, mobileNumber });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙐𝙨𝙚𝙧𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getStaffById = (id) => {
  return http.get(`${config.PardikURL}/Users/${id}`);
};
export const PostAStaff = (fullName, email, password) => {
  return http.post(`${config.PardikURL}/Users`, { fullName, email, password });
};
export const getAllStaffs = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Users/GetAll`, { pageIndex, pageSize });
};
export const deleteStaffById = (id) => {
  return http.delete(`${config.PardikURL}/Users/${id}`);
};
export const PutAssignRoleToStaff = (id, roles) => {
  return http.put(`${config.PardikURL}/Users/AssignRoleToUser/${id}`, { roleIds: roles });
};
export const PutAStaff = (id, fullName, password) => {
  return http.put(`${config.PardikURL}/Users/UpdateUser/${id}`, { fullName, password });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllRoles = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Roles/GetAll`, { pageIndex, pageSize });
};
export const PostARole = (title, description) => {
  return http.post(`${config.PardikURL}/Roles`, { title, description });
};
export const getRoleById = (id) => {
  return http.get(`${config.PardikURL}/Roles/${id}`);
};
export const deleteRoleById = (id) => {
  return http.delete(`${config.PardikURL}/Roles/${id}`);
};
export const PutARole = (id, title, description, resources) => {
  return http.put(`${config.PardikURL}/Roles/${id}`, { title, description, resources: { resourceItemIds: resources } });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙚𝙨𝙤𝙪𝙧𝙘𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllResources = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Resources/GetAll`, { pageIndex, pageSize });
};
export const getResourceById = (id) => {
  return http.get(`${config.PardikURL}/Resources/${id}`);
};
export const PostAResource = (title, description) => {
  return http.post(`${config.PardikURL}/Resources`, { title, description });
};
export const PutAResourceItem = (resourceId, resourceName, resourceKey) => {
  return http.put(`${config.PardikURL}/Resources/AddResourceItem/${resourceId}`, { resourceName, resourceKey });
};
export const deleteResourceById = (id) => {
  return http.delete(`${config.PardikURL}/Resources/${id}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Service Types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllServiceTypes = (pageIndex, pageSize, searchList) => {
  return http.post(`${config.PardikURL}/ServiceTypes/GetAll`, { pageIndex, pageSize, searchList });
};
export const PostAServiceRule = (ServiceRulesTitle, ServiceRulesPrice, startTimeRestriction, endTimeRestriction, serviceRulesisPrivate, isTimeAttached) => {
  return http.post(`${config.PardikURL}/ServiceRules`, { title: ServiceRulesTitle, price: ServiceRulesPrice, fromTime: startTimeRestriction + ":00", endTime: endTimeRestriction + ":00", isPrivate: serviceRulesisPrivate, isTimeAttached });
};
export const PostAServiceType = (fileKey, title, description) => {
  return http.post(`${config.PardikURL}/ServiceTypes`, { fileKey, title, description });
};
export const getServiceTypeById = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/${id}`);
};
export const PutAServiceType = (id, title, fileKey, description, isActive, isScheduleRequired, isMapIncluded) => {
  return http.put(`${config.PardikURL}/ServiceTypes/${id}`, { title, fileKey, description, isActive, isScheduleRequired, isMapIncluded });
};
export const deleteServiceTypeById = (id) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/${id}`);
};
export const deleteServiceTypeScheduleById = (id, day, ruleId) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/RemoveSchedule/${id}`, { data: { day, ruleId } });
};

export const getServicetypeDevicesById = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/GetServiceTypeDevices/${id}`);
};
export const PutServicetypeDevicesById = (id, deviceName, deviceIp, deviceTypeId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/AddServiceTypeDevice/${id}`, { deviceName, deviceIp, deviceTypeId });
};
export const PutServicetypeDeviceItemById = (id, deviceId, deviceName, deviceIp, deviceTypeId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/UpdateServiceTypeDevice/${id}/${deviceId}`, { deviceName, deviceIp, deviceTypeId });
};
export const DeleteServicetypeDeviceItemById = (id, deviceId) => {
  return http.delete(`${config.PardikURL}/ServiceTypes/DeleteServiceTypeDevice/${id}/${deviceId}`);
};
export const PutServiceTypeMapInformation = (id,mapInformation,mapImageKey,icon) => {

  return http.put(`${config.PardikURL}/ServiceTypes/SetServiceTypeMapInformation/${id}`, { mapInformation,mapImageKey,icon});
};
export const getServiceMapDetails = (id) => {
  return http.get(`${config.PardikURL}/ServiceTypes/GetServiceTypeMap/${id}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Service Rules     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllServiceRules = (pageIndex, pageSize, searchList) => {
  return http.post(`${config.PardikURL}/ServiceRules/GetAll`, { pageIndex, pageSize, searchList });
};
export const getServiceRuleById = (id) => {
  return http.get(`${config.PardikURL}/ServiceRules/${id}`);
};
export const PutAServiceRule = (id, title, price, startTime, endTime, isPrivate) => {
  return http.put(`${config.PardikURL}/ServiceRules/${id}`, { title, price, startTime: startTime + ":00", endTime: endTime + ":00", isPrivate });
};
export const PutAServiceTariff = (id, day, ruleId) => {
  return http.put(`${config.PardikURL}/ServiceTypes/AddServiceSchedule/${id}`, { day, ruleId });
};
export const deleteServiceRuleById = (id) => {
  return http.delete(`${config.PardikURL}/ServiceRules/${id}`);
};


//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Device types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllDevicetypes = (pageIndex, pageSize, searchList) => {
  return http.get(`${config.PardikURL}/DeviceTypes`);
};
export const postADevicetype = (deviceModel, deviceType) => {
  return http.post(`${config.PardikURL}/DeviceTypes`, { deviceModel, deviceType });
};
export const getAllDevicecategories = () => {
  return http.get(`${config.PardikURL}/DeviceTypes/GetDeviceCategories`);
};
