import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedRole } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutARole } from "../../../Services/managementServices";

function EditRole({ history }) {
  const dispatch = useDispatch();

  const mySelectedRole = useSelector((state) => state.selectedRole);
  const selectedCheckBox = history.location.state.id;

  const [selectedResource, setSelectedResource] = useState([]);
  const [currentResources, setCurrenctResources] = useState([]);
  const [selectedResourceTitle, setSelectedResourceTitle] = useState("");
  const [selectedResourceDescription, setSelectedResourceDescription] = useState("");

  const [roleTitle, setRoleTitle] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  useEffect(() => {
    dispatch(actionGetSelectedRole(selectedCheckBox));
  }, []);

  const handleFillSelectedResources = (x) => {
    setSelectedResource(x.resourceItemOfRole);
    setCurrenctResources(x.resourceItemOfRole);
    setSelectedResourceTitle(x.title);
    setSelectedResourceDescription(x.description);
  };

  const handleSelectedRolesLength = (resourceItem) => {
    let count = 0;
    if (resourceItem.length > 0) {
      var result = resourceItem.filter((x) => x.isSelected === true);
      count = result.length;
    }
    return count;
  };

  const handleCheckSelectedResources = (e, selectedResource) => {
    if (selectedResource) {
      //------------------
      const copyUserCurrentResources = [...currentResources];
      const newResources = copyUserCurrentResources.find((x) => x.id === selectedResource.id);
      newResources.isSelected = e;

      setCurrenctResources(copyUserCurrentResources);
      //-----------------
    }
  };
  const updateRoleHandler = async(e) => {

    e.preventDefault()

    const selectedRolesId=[]
    const title = roleTitle ? roleTitle : mySelectedRole.title;
    const description = roleDescription ? roleDescription : mySelectedRole.description;
    mySelectedRole.resourceOfRoles.map(item=>{
      item.resourceItemOfRole.forEach(x=>{
        if(x.isSelected){
          selectedRolesId.push(x.id)
        }
      })
    })

    const {status}=await PutARole(selectedCheckBox,title,description,selectedRolesId)
    if(status===200){
      toast.success('Updated Successfully')
    }
  };

  return (
    <div className="ContentBody">
      {mySelectedRole ? (
        <div className="BodyPadding">
          <form className="EditPageThreeColumn">
            <div className="EditPageThreeColumnItem">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Role Title</div>
                <input className="SlidingMenuTextBox" id="roleTitle" value={roleTitle ? roleTitle : mySelectedRole.title} onChange={(e) => setRoleTitle(e.target.value)} />
                <div className="SlidingMenuMoreDetail">The name of the role should be written here. This name will later be used for defined staff and can be assigned to them.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Announcement Description</div>
                <textarea className="SlidingMenuTextArea" id="roleDescription" value={roleDescription ? roleDescription : mySelectedRole.description} onChange={(e) => setRoleDescription(e.target.value)} />
                <div className="SlidingMenuMoreDetail">A brief description about the role</div>
              </div>
            </div>
            <div className="EditPageThreeColumnItem">
              <div className="EditPageItemBorder">
                <div className="EditPageItemHeader">
                  <div className="SlidingMenuTitle">Resources</div>
                </div>
                {mySelectedRole.resourceOfRoles
                  ? mySelectedRole.resourceOfRoles.map((item, index) => (
                      <div key={index} className="EditPageColomnSelectableItem" onClick={() => handleFillSelectedResources(item)}>
                        <div>{item.title}</div>
                        <div>
                          {handleSelectedRolesLength(item.resourceItemOfRole)}/{item.resourceItemOfRole.length}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="EditPageThreeColumnItem">
              <div className="EditPageItemBorderSmallerDescription">
                <div className="EditTitle">{selectedResourceTitle}</div>
                <div className="EditDescriptions">{selectedResourceDescription}</div>
              </div>
              <div className="EditPageItemBorderSmaller">
                <div className="EditPageItemHeader">
                  <div className="SlidingMenuTitle">Resources</div>
                </div>
                {currentResources.length > 0
                  ? currentResources.map((item) => (
                      <div className="EditPageColomnSelectableItem">
                        <div>{item.resourceName}</div>
                        <input className="Checkbox" type="checkbox" name="" id="" onChange={(e) => handleCheckSelectedResources(e.target.checked, item)} checked={item.isSelected} />
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className="SlidingMenuFooterContainer FullWidthContainer">
              <div className="EditPgefooter">
                <div className="SlidingMenuSavebutton" onClick={(e) => updateRoleHandler(e)}>
                  Save
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
}
export default withRouter(EditRole);
