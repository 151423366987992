import React, { useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { PostAStaff } from "../../../Services/managementServices";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

function AddStaffSlidingMenu(props) {
  const [staffName, setStaffName] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  const [repeatStaffPassword, setRepeatStaffPassword] = useState("");

  const reset = () => {
    setStaffName("");
    setStaffEmail("");
  };

  const addNewStaffHandler = async (e) => {
    e.preventDefault();
    if (repeatStaffPassword === staffPassword) {
      try {
        const { data, status } = await PostAStaff(staffName, staffEmail, staffPassword);
        if (status === 200) {
          reset();
          toast.success("Staff added Successfully");
          props.history.push("/Staffs");
        }
      } catch (ex) {
        toast.error(ex.response.errorDescription)
      }
     
    } else {
        toast.error("Passwords Does not match");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">Add new Staff</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>
      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">Staff's Full name</div>
          <input className="SlidingMenuTextBox" id="roleTitle" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
          <div className="SlidingMenuMoreDetail">Fullname of the the user you want to define.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">Email</div>
          <input className="SlidingMenuTextBox" type="email" id="roleTitle" value={staffEmail} onChange={(e) => setStaffEmail(e.target.value)} />
          <div className="SlidingMenuMoreDetail">The staff's email</div>
        </div>
      </div>

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">Password</div>
          <input className="SlidingMenuTextBox" type="password" id="roleTitle" value={staffPassword} onChange={(e) => setStaffPassword(e.target.value)} />
          <div className="SlidingMenuMoreDetail">Set a password for your staff to log in with.</div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">Repaet Password</div>
          <input className="SlidingMenuTextBox" type="password" id="roleTitle" value={repeatStaffPassword} onChange={(e) => setRepeatStaffPassword(e.target.value)} />
          <div className="SlidingMenuMoreDetail">The staff's email</div>
        </div>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuMoreDetail">After adding the staff you will be redirected to the added staff's edit page for the roles to be assigned.</div>
      </div>
      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="SlidingMenuFooter">
          <div onClick={props.onClickCloseHandler} className="SlidingMenuCancelbutton">
            Cancel
          </div>
          <button type="sumbit" className="SlidingMenuSavebutton" onClick={(e) => addNewStaffHandler(e)}>
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddStaffSlidingMenu);
