import React from "react";
import { useDispatch } from "react-redux";
import { actionGetAllRoles, actionGetAllResources, actionGetAllAnnouncements, actionGetAllHotelServices, actionGetAllFoods, actionGetAllMirrorCompliments, actionGetAllResidents } from "../../actions";
import "./Pagination.css";
import { actionGetAllServiceRules } from "./../../actions/index";

export default function Pagination(props) {
  const dispatch = useDispatch();

  const setActionType = (index, selectOption) => {
    switch (props.actionType) {
      case "Roles":
        dispatch(actionGetAllRoles(index, props.PageSize));
        break;
      case "Residents":
        dispatch(actionGetAllResidents(index, props.PageSize));
        break;
      case "Resources":
        dispatch(actionGetAllResources(index, props.PageSize));
        break;
      case "Announcements":
        dispatch(actionGetAllAnnouncements(index, props.PageSize));
        break;
      case "HotelServices":
        dispatch(actionGetAllHotelServices(index, props.PageSize));
        break;
      case "Foods":
        dispatch(actionGetAllFoods(index, props.PageSize));
        break;
      case "MirrorCompliments":
        dispatch(actionGetAllMirrorCompliments(index, props.PageSize));
        break;
      case "ServiceRules":
        dispatch(actionGetAllServiceRules(index, props.PageSize));
        break;
      case "Devices":
        dispatch(actionGetAllServiceRules(index, props.PageSize));
        break;
      default:
        console.log("No pagination");
    }
  };

  let PageNumber = [];
  for (let i = 0; i < props.TotalPages; i++) {
    if (i + 1 === props.PageIndex) {
      PageNumber.push(
        <div key={i + 1} onClick={() => setActionType(i + 1, props.PageSize)} className="PageNumber PageSelected">
          {i + 1}
        </div>
      );
    } else {
      PageNumber.push(
        <div key={i + 1} onClick={() => setActionType(i + 1, props.PageSize)} className="PageNumber">
          {i + 1}
        </div>
      );
    }
  }
  return (
    <div className="PaginationContaner">
      <div>{props.HasPreviousPage ? <span onClick={() => setActionType(props.PageIndex - 1)} className="icon-rightarrow PageChangerButton"></span> : <span className="icon-rightarrow PageChangerButton PageChangerDisabled"></span>}</div>
      <div className="PageNumberContainer">{PageNumber}</div>
      <div>{props.HasNextPage ? <span onClick={() => setActionType(props.PageIndex + 1)} className="icon-leftarrow PageChangerButton"></span> : <span className="icon-leftarrow PageChangerButton PageChangerDisabled"></span>}</div>
    </div>
  );
}
