import React, { useEffect, useState } from "react";
import "../../../components/slidingmenu/slidingmenu.css";
import { actionGetAllLanguages } from "../../../actions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PostAMirrorCompliment } from "../../../Services/mirrorServices";

function AddMirrorCompliments(props) {
  const [mirrorComplimentsTitle, setMirrorComplimentsTitle] = useState("");
  const [mirrorComplimentsExpirationDate, setMirrorComplimentsExpirationDate] = useState(null);

  const [mirrorComplimentsLanguageId, setMirrorComplimentsLanguageId] = useState("");

  const [expiryDateHandler, setExpiryDateHandler] = useState(false);
  const [languageDDlist, setLanguageDDlist] = useState("");

  const reset = () => {
    setMirrorComplimentsTitle("");
    setMirrorComplimentsExpirationDate("");
    setMirrorComplimentsLanguageId("");
  };

  const dispatch = useDispatch();

  const getLanguages = async () => {
    dispatch(actionGetAllLanguages());
  };
  const myLanguages = useSelector((state) => state.allLanguages);

  const addNewMirrorComplimentsHandler = async (e) => {
    e.preventDefault();
    const { status } = await PostAMirrorCompliment(mirrorComplimentsTitle, mirrorComplimentsLanguageId, mirrorComplimentsExpirationDate);
    if (status === 200) {
      reset();
      toast.success("Compliment added Successfully");
      props.onClickCloseHandler();
      props.history.push("/MirrorCompliments");
    }
  };

  return (
    <form className="SlidingMenuContainer">
      <div className="SlidingMenuHeader">
        <div className="SlidingMenuTitle">اضافه کردن خوشامدگویی جدید</div>
        <span onClick={props.onClickCloseHandler} className="icon-closeX SlidingMenuCloseButton"></span>
      </div>

      <div className="FullWidthContainer">
        <div className="SlidingMenuItemTitle">عنوان خوش آمد گویی</div>
        <input className="SlidingMenuTextBox" id="announcementTitle" value={mirrorComplimentsTitle} onChange={(e) => setMirrorComplimentsTitle(e.target.value)} />
        <div className="SlidingMenuMoreDetail">
          این عنوان در صفحه اصلی تمامی دستگاه ها به نمایش گذاشته میشود و مخاطب به راحتی میتواند این متن را ببیند. همچنین برای عنوان مطالبی کوتاه یا یاذذاشت ها جهت آگاهیی بیشتر مخاطبین میشود از این خوشامد گویی ها استفاده کرد.
        </div>
      </div>

      {/* 
      <div className="TitleAndSliderCheck">
        <div className="HalfWidthInsideFlex">
          <div className="FullWidthContainer">
            <div className="SlidingMenuItemTitle"></div>
            <div className="SlidingMenuTextBox DropDownContainer">
              <img className="DropDownMenuImage" src={"/images/countries/" + languageDDlist + ".png"} alt="" />

              <select
                onClick={(e) => {
                  getLanguages();
                }}
                onChange={(e) => {
                  setLanguageDDlist(e.target.value.substring(0, 2));
                  setMirrorComplimentsLanguageId(e.target.value.substring(2));
                }}
                className="DropDownSelect"
                name="LangId"
                id="LangId"
                defaultValue="SelectLanguage">
                <option value="SelectLanguage" disabled>
                  Select Language
                </option>

                {myLanguages.map((item, index) => (
                  <option key={index} value={item.code + item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div> */}

      <div className="FullWidthFlex">
        <div className="HalfWidthContainer">
          <div className="TitleAndSliderCheck">
            <div className="SlidingMenuItemTitle">تاریخ انقضا</div>
            <label className="switch">
              <input type="checkbox" onChange={(e) => (expiryDateHandler ? setExpiryDateHandler(false) : (setExpiryDateHandler(true), setMirrorComplimentsExpirationDate(null)))} />
              <span className="switchslider round"></span>
            </label>
          </div>
        </div>

        <div className="HalfWidthContainer">
          <div className="SlidingMenuItemTitle">تاریخ اتمام</div>
          {expiryDateHandler ? <input onChange={(e) => setMirrorComplimentsExpirationDate(e.target.value)} className="SlidingMenuTextBox" type="Date" id="DueDate" /> : <input className="SlidingMenuTextBox" type="Date" id="DueDate" disabled />}

          <div className="SlidingMenuMoreDetail">در صورت وجود تاریخ انقضا عنوان وارد شده پس از رسیدن به تاریخ اتمام دیگر برای مخاطبین به نمایش گذاشته نخواهد شد.</div>
        </div>
      </div>

      <div className="SlidingMenuFooterContainer FullWidthContainer">
        <div className="ButtonsContainer">
          {mirrorComplimentsTitle && mirrorComplimentsLanguageId ? (
            <button type="sumbit" className="SaveButton" onClick={(e) => addNewMirrorComplimentsHandler(e)}>
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          ) : (
            <button type="sumbit" className="SaveButton" disabled>
              <span className="icon-SaveButton"></span>
              ذخیره
            </button>
          )}
          <div onClick={props.onClickCloseHandler} className="CancelButton">
            <span className="icon-closeX"></span>
            لغو
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(AddMirrorCompliments);
