import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/loginpage/LoginPage";
import LandingPage from "./pages/landingpage/LandingPage";

import ServiceTypes from "./pages/BuildingManagement/servicetypes/ServiceTypes";

import Staffs from "./pages/Management/staffs/Staffs";
import Roles from "./pages/Management/roles/Roles";
import EditRole from "./pages/Management/roles/EditRole";
import EditStaff from "./pages/Management/staffs/EditStaff";
import Resources from "./pages/Management/resources/Resources";
import ResourceDetail from "./pages/Management/resources/ResourceDetail";

import Announcements from "./pages/mirrorsection/announcements/Announcements";
import EditAnnouncement from "./pages/mirrorsection/announcements/EditAnnouncement";
import MirrorCompliments from "./pages/mirrorsection/mirrorcompliment/MirrorCompliments";
import EditMirrorCompliment from "./pages/mirrorsection/mirrorcompliment/EditMirrorCompliment";

import Settings from "./pages/settings/Settings";
import Layout from "./components/layout/Layout";
import { useDispatch } from "react-redux";
import { loginAction, isLoggedIn } from "./actions/index";
import Logout from "./components/ui/logout/Logout";
import EditServiceTypes from "./pages/BuildingManagement/servicetypes/EditServiceTypes";
import Residents from "./pages/Management/Residents/Residents";
import EditResident from "./pages/Management/Residents/EditResident";
import ServiceRules from "./pages/BuildingManagement/servicerules/ServiceRules";
import EditServiceRules from "./pages/BuildingManagement/servicerules/EditServiceRules";
import ResidentProfile from "./pages/ResidentManagement/ResidentProfile/ResidentProfile";
import ResidentMessages from "./pages/ResidentManagement/ResidentMessages/ResidentMessages";
import LayoutResidents from "./components/layout/LayoutResidents";
import ResidentOperationSuccessful from "./pages/ResidentManagement/ResidentOperationSuccessful/ResidentOperationSuccessful";
import Devices from "./pages/Management/Devices/Devices";

const App = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState(1);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("residentialManagement"));
    if (token) {
      const dateNow = Date.now() / 1000;
      if (token.decodedToken.exp < dateNow) {
        localStorage.removeItem("residentialManagement");
        dispatch(loginAction(null));
        dispatch(isLoggedIn(false));
      } else {
        dispatch(loginAction(token));
        dispatch(isLoggedIn(true));
      }
    } else {
      //window.location.reload()
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <LoginPage />
          </Route>
          <Route path="/Logout" exact>
            <Logout />
          </Route>
          {userType === 1 ? (
            <Layout>
              <Route path="/LandingPage" exact>
                <LandingPage />
              </Route>
              <Route path="/Residents">
                <Residents />
              </Route>
              <Route path="/EditResident">
                <EditResident />
              </Route>
              <Route path="/Staffs">
                <Staffs />
              </Route>
              <Route path="/Roles">
                <Roles />
              </Route>
              <Route path="/EditRole">
                <EditRole />
              </Route>
              <Route path="/EditStaff">
                <EditStaff />
              </Route>
              <Route path="/Resources">
                <Resources />
              </Route>
              <Route path="/ResourceDetail">
                <ResourceDetail />
              </Route>
              <Route path="/ServiceTypes">
                <ServiceTypes />
              </Route>
              <Route path="/EditServiceTypes">
                <EditServiceTypes />
              </Route>
              <Route path="/ServiceRules">
                <ServiceRules />
              </Route>
              <Route path="/EditServiceRules">
                <EditServiceRules />
              </Route>
              <Route path="/Announcements">
                <Announcements />
              </Route>
              <Route path="/EditAnnouncement">
                <EditAnnouncement />
              </Route>
              <Route path="/Devices">
                <Devices />
              </Route>
              <Route path="/MirrorCompliments">
                <MirrorCompliments />
              </Route>
              <Route path="/EditMirrorCompliment">
                <EditMirrorCompliment />
              </Route>
              <Route path="/Settings">
                <Settings />
              </Route>
            </Layout>
          ) : null}
          {userType === 2 ? (
            <LayoutResidents>
              <Route path="/ResidentProfile" exact>
                <ResidentProfile />
              </Route>
              <Route path="/ResidentMessages" exact>
                <ResidentMessages />
              </Route>
              <Route path="/ResidentOperationSuccessful" exact>
                <ResidentOperationSuccessful />
              </Route>
            </LayoutResidents>
          ) : null}
        </Switch>

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </BrowserRouter>
    </div>
  );
};

export default App;
