import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function MirrorComplimentsGridTable(props) {
  const myMirrorCompliments = useSelector((state) => state.allMirrorCompliments);
  const [selectedCheckBox, setSelectedCheckBox] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox} IsSelected={isSelected} NavlinkAdress={"/EditMirrorCompliment"} />
      {myMirrorCompliments.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">خوشامدگویی</div>
                <div className="attribute">وضعیت</div>
                <div className="attribute">تاریخ انقضا</div>
              </li>
              <form>
                {myMirrorCompliments.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    {/* ----------------------Row Number---------------------- */}
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="AnnouncementsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    {/* ----------------------Title---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Title">
                        {item.items[0].text}
                      </div>
                    </div>
                    {/* ----------------------IsActive---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.isActive ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div>
                    {/* ----------------------expireDate---------------------- */}
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Due Date">
                        {item.expireDate ? new Date(item.expireDate).toLocaleDateString("en-CA") : null}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myMirrorCompliments.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myMirrorCompliments.totalCount}
            TotalPages={myMirrorCompliments.totalPages}
            HasPreviousPage={myMirrorCompliments.hasPreviousPage}
            HasNextPage={myMirrorCompliments.hasNextPage}
            actionType="MirrorCompliments"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">خوشامدگویی</div>
                <div className="attribute">وضعیت</div>
                <div className="attribute">تاریخ انقضا</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}
