import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionGetAllServiceRules } from "./../../../actions/index";

function ServiceRulesGridTable(props) {
  const myServiceRules = useSelector((state) => state.allServiceRules);
  const dispatch = useDispatch();

  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [pageSize, setPageSize] = useState(8);

  const [filterRuleName, setFilterRuleName] = useState("");
  const [filterRulePrice, setFilterRulePrice] = useState("");
  const [filterRuleCategory, setFilterRuleCategory] = useState("");
  const [filterRuleTimeFrom, setFilterRuleTimeFrom] = useState("");
  const [filterRuleTimeTo, setFilterRuleTimeTo] = useState("");

  function Nullifier() {
    setIsSelected(false);
    setSelectedCheckBox({});
    setIsChecked(false);
  }

  const filterData = (searchVal, field) => {
    let filterData = [];
    let filterName = field === "title" ? searchVal : filterRuleName;
    let filterPrice = field === "price" ? searchVal : filterRulePrice;
    let filterCategory = field === "isPrivate" ? searchVal : filterRuleCategory;
    let filterTimeFrom = field === "startTime" ? searchVal : filterRuleTimeFrom;
    let filterTimeTo = field === "endTime" ? searchVal : filterRuleTimeTo;

    if (field === "title") {
      setFilterRuleName(searchVal);
    } else if (field === "price") {
      setFilterRulePrice(searchVal);
    } else if (field === "isPrivate") {
      setFilterRuleCategory(searchVal);
    } else if (field === "startTime") {
      setFilterRuleTimeFrom(searchVal);
    } else if (field === "endTime") {
      setFilterRuleTimeTo(searchVal);
    }

    if (filterName) filterData.push({ searchField: "title", searchValue: filterName, operatorType: "Contains" });
    if (filterPrice) filterData.push({ searchField: "price", searchValue: filterPrice, operatorType: "Equals" });
    if (filterCategory) filterData.push({ searchField: "isPrivate", searchValue: filterCategory, operatorType: "Equals" });
    if (filterTimeFrom) filterData.push({ searchField: "startTime", searchValue: filterTimeFrom, operatorType: "GreaterThanOrEqual" });
    if (filterTimeTo) filterData.push({ searchField: "endTime", searchValue: filterTimeTo, operatorType: "LessThanOrEqual" });

    dispatch(actionGetAllServiceRules(1, pageSize, filterData));
  };

  return (
    <>
      <CrudOperators
        onClickOpenHandler={props.onClickOpenHandler}
        onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)}
        SelectedId={selectedCheckBox.id}
        selectedData={selectedCheckBox}
        IsSelected={isSelected}
        NavlinkAdress={"/EditServiceRules"}
      />
      {myServiceRules ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام قانون</div>
                <div className="attribute">هزینه</div>
                <div className="attribute">دسته بندی</div>
                <div className="attribute">ساعت</div>
              </li>
              <li className="item item-container">
                <div className="attribute Firstchild">جستجو</div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" name="ruleName" value={filterRuleName} onChange={(e) => filterData(e.target.value, "title")}></input>
                </div>
                <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" name="rulePrice" value={filterRulePrice} onChange={(e) => filterData(e.target.value, "price")}></input>
                </div>
                {/* <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" name="ruleCategory"></input>
                </div> */}
                <div className="GridItemSearchContainer">
                  <select className="GridItemSearch" name="ruleCategory" value={filterRuleCategory} onChange={(e) => filterData(e.target.value, "isPrivate")}>
                    <option value={""} selected>
                      همه
                    </option>
                    <option value={true}>خصوصی</option>
                    <option value={false}>عمومی</option>
                  </select>
                </div>
                {/* <div className="FullWidthFlex"> */}
                {/* <div className="HalfWidthContainer"> */}
                {/* <div className="SlidingMenuItemTitle">زمان شروع</div> */}
                <div className="GridItemSearchContainer">
                  <select className="GridItemSearch" name="DevicetypeId" id="DevicetypeId" value={filterRuleTimeFrom} onChange={(e) => filterData(e.target.value, "startTime")}>
                    <option value={""} selected></option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>
                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>
                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                    <option value="23:59">24:00</option>
                  </select>
                  <select className="GridItemSearch" name="DevicetypeId" id="DevicetypeId" value={filterRuleTimeTo} onChange={(e) => filterData(e.target.value, "endTime")}>
                    <option value={""} selected></option>
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="10:00">10:00</option>
                    <option value="11:00">11:00</option>
                    <option value="12:00">12:00</option>
                    <option value="13:00">13:00</option>
                    <option value="14:00">14:00</option>
                    <option value="15:00">15:00</option>
                    <option value="16:00">16:00</option>
                    <option value="17:00">17:00</option>
                    <option value="18:00">18:00</option>
                    <option value="19:00">19:00</option>
                    <option value="20:00">20:00</option>
                    <option value="21:00">21:00</option>
                    <option value="22:00">22:00</option>
                    <option value="23:00">23:00</option>
                    <option value="23:59">24:00</option>
                  </select>
                </div>
                {/* <div className="SlidingMenuMoreDetail">زمان شروع این قانون. توجه داشته باشید این ساعت باید کوچکتر از ساعت پایان باشد.</div> */}
                {/* </div> */}

                {/* <div className="HalfWidthContainer"> */}
                {/* <div className="SlidingMenuItemTitle">زمان پایان</div> */}
                {/* <div className="SlidingMenuTextBox DropDownContainer">
                      <select className="DropDownSelectNoFlag" name="DevicetypeId" id="DevicetypeId" value={filterRuleTimeTo} onChange={(e) => setFilterRuleTimeTo(e.target.value)}>
                        <option value="Choose a Device Type" disabled selected>
                          لطفا زمان شروع مورد نظر را انتخاب نمایید.
                        </option>
                        <option value="01:00">01:00</option>
                        <option value="02:00">02:00</option>
                        <option value="03:00">03:00</option>
                        <option value="04:00">04:00</option>
                        <option value="05:00">05:00</option>
                        <option value="06:00">06:00</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <option value="23:59">24:00</option>
                      </select>
                    </div> */}
                {/* <div className="SlidingMenuMoreDetail">زمان پایان این قانون. توجه داشته باشید این ساعت باید بزرگتر از ساعت شروع باشد.</div>
                  </div> */}
                {/* </div> */}
                {/* <div className="GridItemSearchContainer">
                  <input className="GridItemSearch" name="ruleTime"></input>
                </div> */}
              </li>
              <form>
                {myServiceRules.entities
                  ? myServiceRules.entities.map((item, index) => (
                      <li key={index + 1} className="item item-container">
                        <div className="attribute Firstchild" data-name="#">
                          <input
                            className="Checkbox"
                            type="radio"
                            name="ServiceRulesItem"
                            value={item.id}
                            id={item.id}
                            checked={isChecked === item.id}
                            onChange={() => {
                              setIsChecked(item.id);
                              setSelectedCheckBox(item);
                              setIsSelected(true);
                            }}
                          />
                          {index + 1}
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="نام قانون">
                            {item.title}
                          </div>
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="هزینه">
                            {item.price}
                          </div>
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="دسته بندی">
                            {item.isPrivate ? "خصوصی" : "عمومی"}
                          </div>
                        </div>
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="زمان">
                            {item.startTime} - {item.endTime}
                          </div>
                        </div>
                      </li>
                    ))
                  : null}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myServiceRules.pageIndex}
            PageSize={myServiceRules.pageSize}
            TotalCount={myServiceRules.totalCount}
            TotalPages={myServiceRules.totalPages}
            HasPreviousPage={myServiceRules.hasPreviousPage}
            HasNextPage={myServiceRules.hasNextPage}
            actionType="ServiceRules"
          />
        </div>
      ) : (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام قانون</div>
                <div className="attribute">هزینه</div>
                <div className="attribute">دسته بندی</div>
                <div className="attribute">ساعت</div>
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(ServiceRulesGridTable);
