import {React } from 'react';
import { useSelector } from 'react-redux';

export default function LandingPage() {
  const mySelectedServiceRules = useSelector((state) => state.user);

  return (
    <>
    {mySelectedServiceRules.decodedToken ?
          <div>{mySelectedServiceRules.decodedToken.unique_name}</div>
    :null}
    </>
  );
}
