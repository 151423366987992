import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function RolesGridTable(props) {
  const myRoles = useSelector((state) => state.allRoles);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditRole"} />
      {myRoles.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام نقش</div>
                <div className="attribute">توضیحات</div>
              </li>
              <form>
                {myRoles.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="RoleItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.title}
                      </div>
                    </div>
                    <div className="attribute-container vendor-information">
                      <div className="attribute" data-name="Description">
                        {item.description}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination PageIndex={myRoles.pageIndex} PageSize={props.PassPageSize} TotalCount={myRoles.totalCount} TotalPages={myRoles.totalPages} HasPreviousPage={myRoles.hasPreviousPage} HasNextPage={myRoles.hasNextPage} actionType="Roles" />
        </div>
      ) : null}
    </>
  );
}
