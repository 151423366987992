import {
  getAllRoles,
  getRoleById,
  getAllResources,
  getResourceById,
  deleteRoleById,
  getAllResidents,
  getResidentById,
  deleteResidentById,
  getAllStaffs,
  getStaffById,
  deleteStaffById,
  getAllServiceRules,
  getServiceRuleById,
  getAllServiceTypes,
  getServiceTypeById,
  getAllDevicetypes,
  getAllDevicecategories,
  getServicetypeDevicesById,
} from "../Services/managementServices";
import {
  deleteAnnouncementById,
  deleteAnnouncementItemById,
  getAboutHotel,
  getAllAnnouncements,
  getAllFeatureTypes,
  getAllFoodCategories,
  getAllFoods,
  getAllHotelServices,
  getAllMealTimes,
  getAllMirrorCompliments,
  getAllProductTypes,
  getAnnouncementById,
  getFoodById,
  getFoodCategoryById,
  getHotelServiceById,
  getMealTimeByid,
  getMirrorComplimentById,
} from "../Services/mirrorServices";
import { getAllLanguages } from "../Services/settings";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     User     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const loginAction = (user) => {
  return {
    type: "LOGIN",
    payload: user,
  };
};
export const logoutAction = (user) => {
  return {
    type: "CLEAR_USER",
    payload: user,
  };
};
export const isLoggedIn = (isLogin) => {
  return {
    type: "ISLOGGED",
    payload: isLogin,
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllRoles = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data, status } = await getAllRoles(myPageIndex, myPageSize);
    await dispatch({
      type: "GETROLES",
      payload: data,
    });
  };
};

export const actionGetSelectedRole = (id) => {
  return async (dispatch) => {
    const { data, status } = await getRoleById(id);
    await dispatch({
      type: "GETSELECTEDROLE",
      payload: data,
    });
  };
};
export const actionDeleteRoleById = (id) => {
  return async (dispatch) => {
    const { data, status } = await deleteRoleById(id);
    await dispatch({
      type: "DELETESELECTEDROLE",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Resident     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const actionGetAllResidents = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data, status } = await getAllResidents(myPageIndex, myPageSize);
    await dispatch({
      type: "GETRESIDENTS",
      payload: data,
    });
  };
};

export const actionGetSelectedResident = (id) => {
  return async (dispatch) => {
    const { data, status } = await getResidentById(id);
    await dispatch({
      type: "GETSELECTEDRESIDENT",
      payload: data,
    });
  };
};
export const actionDeleteResidentById = (id) => {
  return async (dispatch) => {
    const { data, status } = await deleteResidentById(id);
    await dispatch({
      type: "DELETESELECTEDRESIDENT",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Resident     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙐𝙨𝙚𝙧𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const actionGetAllUsers = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data } = await getAllStaffs(myPageIndex, myPageSize);
    await dispatch({
      type: "GETUSERS",
      payload: data,
    });
  };
};

export const actionGetSelectedUser = (id) => {
  return async (dispatch) => {
    const { data } = await getStaffById(id);
    await dispatch({
      type: "GETSELECTEDUSER",
      payload: data,
    });
  };
};
export const actionDeleteUserById = (id) => {
  return async (dispatch) => {
    const { data } = await deleteStaffById(id);
    await dispatch({
      type: "DELETESELECTEDUSER",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙐𝙨𝙚𝙧𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙚𝙨𝙤𝙪𝙧𝙘𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const actionGetAllResources = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data, status } = await getAllResources(myPageIndex, myPageSize);
    await dispatch({
      type: "GETRESOURCES",
      payload: data,
    });
  };
};
export const actionGetSelectedResources = (id) => {
  return async (dispatch) => {
    const { data, status } = await getResourceById(id);
    await dispatch({
      type: "GETSELECTEDRESOURCES",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙚𝙨𝙤𝙪𝙧𝙘𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Announcements     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const actionGetAllAnnouncements = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data, status } = await getAllAnnouncements(myPageIndex, myPageSize);
    await dispatch({
      type: "GETANNOUNCEMENTS",
      payload: data,
    });
  };
};
export const actionGetSelectedAnnouncement = (id) => {
  return async (dispatch) => {
    const { data, status } = await getAnnouncementById(id);
    await dispatch({
      type: "GETSELECTEDANNOUNCEMENTS",
      payload: data,
    });
  };
};

export const actionDeleteAnnouncementById = (id) => {
  return async (dispatch) => {
    const { data, status } = await deleteAnnouncementById(id);
    await dispatch({
      type: "DELETESELECTEDANNOUNCEMENT",
      payload: data,
    });
  };
};
export const actionDeleteAnnouncementItemById = (id) => {
  return async (dispatch) => {
    const { data, status } = await deleteAnnouncementItemById(id);
    await dispatch({
      type: "DELETESELECTEDANNOUNCEMENTITEM",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Announcements     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     HotelServices     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllHotelServices = (myPageIndex, myPageSize) => {
  return async (dispatch) => {
    const { data, status } = await getAllHotelServices(myPageIndex, myPageSize);
    await dispatch({
      type: "GETHOTELSERVICES",
      payload: data,
    });
  };
};
export const actionGetSelectedHotelService = (id) => {
  return async (dispatch) => {
    const { data, status } = await getHotelServiceById(id);
    await dispatch({
      type: "GETSELECTEDHOTELSERVICE",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     HotelServices     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Meal Time     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllMealTimes = () => {
  return async (dispatch) => {
    const { data, status } = await getAllMealTimes();
    await dispatch({
      type: "GETMEALTIMES",
      payload: data,
    });
  };
};
export const actionGetSelectedMealTime = (id) => {
  return async (dispatch) => {
    const { data, status } = await getMealTimeByid(id);
    await dispatch({
      type: "GETSELECTEDMEALTIME",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Meal Time     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Food Categories     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllFoodCategories = () => {
  return async (dispatch) => {
    const { data, status } = await getAllFoodCategories();
    await dispatch({
      type: "GETFOODCATEGORIES",
      payload: data,
    });
  };
};
export const actionGetSelectedFoodCategory = (id) => {
  return async (dispatch) => {
    const { data, status } = await getFoodCategoryById(id);
    await dispatch({
      type: "GETSELECTEDFOODCATEGORIES",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Food Categories     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Foods     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllFoods = () => {
  return async (dispatch) => {
    const { data, status } = await getAllFoods();
    await dispatch({
      type: "GETFOODS",
      payload: data,
    });
  };
};
export const actionGetSelectedFood = (id) => {
  return async (dispatch) => {
    const { data, status } = await getFoodById(id);
    await dispatch({
      type: "GETSELECTEDFOOD",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Foods     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Mirror Compliments     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllMirrorCompliments = () => {
  return async (dispatch) => {
    const { data, status } = await getAllMirrorCompliments();
    await dispatch({
      type: "GETMIRRORCOMPLIMENTS",
      payload: data,
    });
  };
};
export const actionGetMirrorCompliment = (id) => {
  return async (dispatch) => {
    const { data, status } = await getMirrorComplimentById(id);
    await dispatch({
      type: "GETSELECTEDMIRRORCOMPLIMENT",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Mirror Compliments     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Product Types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllProductTypes = () => {
  return async (dispatch) => {
    const { data, status } = await getAllProductTypes();
    await dispatch({
      type: "GETPRODUCTTYPES",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Product Types     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Features     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllFeatureTypes = () => {
  return async (dispatch) => {
    const { data, status } = await getAllFeatureTypes();
    await dispatch({
      type: "GETFEATURETYPES",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Features     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     About Hotel     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAboutHotel = () => {
  return async (dispatch) => {
    const { data, status } = await getAboutHotel();
    await dispatch({
      type: "GETABOUTHOTEL",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     About Hotel     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Settings     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const actionGetAllLanguages = () => {
  return async (dispatch) => {
    const { data, status } = await getAllLanguages();
    await dispatch({
      type: "GETLANGUAGES",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Settings     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Building Management     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllServiceRules = (myPageIndex, myPageSize, mySearchList) => {
  return async (dispatch) => {
    const { data, status } = await getAllServiceRules(myPageIndex, myPageSize, mySearchList);
    await dispatch({
      type: "GETSERVICERULES",
      payload: data,
    });
  };
};

export const actionGetAllServiceTypes = (myPageIndex, myPageSize,mySearchList) => {
  return async (dispatch) => {
    const { data, status } = await getAllServiceTypes(myPageIndex, myPageSize,mySearchList);
    await dispatch({
      type: "GETSERVICETYPES",
      payload: data,
    });
  };
};

export const actionGetSelectedServiceRules = (id) => {
  return async (dispatch) => {
    const { data, status } = await getServiceRuleById(id);
    await dispatch({
      type: "GETSELECTEDSERVICERULES",
      payload: data,
    });
  };
};

export const actionGetSelectedServiceTypes = (id) => {
  return async (dispatch) => {
    const { data, status } = await getServiceTypeById(id);
    await dispatch({
      type: "GETSELECTEDSERVICETYPES",
      payload: data,
    });
  };
};

export const actionGetServicetypeDevicesById = (id) => {
  return async (dispatch) => {
    const { data, status } = await getServicetypeDevicesById(id);
    await dispatch({
      type: "GETSERVICETYPEDEVICESBYID",
      payload: data,
    });
  };
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Building Management     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Building Management     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const actionGetAllDeviceTypes = (myPageIndex, myPageSize, mySearchList) => {
  return async (dispatch) => {
    const { data, status } = await getAllDevicetypes();
    await dispatch({
      type: "GETDEVICETYPES",
      payload: data,
    });
  };
};
export const actionGetAllDevicecategories = () => {
  return async (dispatch) => {
    const { data, status } = await getAllDevicecategories();
    await dispatch({
      type: "GETDEVICECATEGORIES",
      payload: data,
    });
  };
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Building Management     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
