import React, { useState } from "react";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";
import CrudOperators from "../../../components/crudoperators/CrudOperators";
import { useSelector } from "react-redux";

export default function ResidentsGridTable(props) {
  const myResidents = useSelector((state) => state.allResidents);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});
  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <CrudOperators onClickOpenHandler={props.onClickOpenHandler} onClickDeleteOpenHandler={() => props.onClickDeleteOpenHandler(selectedCheckBox)} SelectedId={selectedCheckBox.id} IsSelected={isSelected} NavlinkAdress={"/EditResident"} />
      {myResidents.entities ? (
        <div className="GridViewAndPaginationContainer">
          <div className="TableGridViewContainer">
            <ol className="collection collection-container">
              <li className="item item-container DataGridHeader">
                <div className="attribute Firstchild">شماره ردیف</div>
                <div className="attribute">نام کاربری</div>
                <div className="attribute">نام ساکن</div>
                <div className="attribute">موبایل</div>
                <div className="attribute">وضعیت</div>
              </li>
              <form>
                {myResidents.entities.map((item, index) => (
                  <li key={index + 1} className="item item-container">
                    <div className="attribute Firstchild" data-name="#">
                      <input
                        className="Checkbox"
                        type="radio"
                        name="ResidentsItem"
                        value={item.id}
                        id={item.id}
                        onChange={() => {
                          setSelectedCheckBox(item);
                          setIsSelected(true);
                        }}
                      />
                      {index + 1}
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Residents Name">
                        {item.username}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Residents Name">
                        {item.displayName}
                      </div>
                    </div>
                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Residents Name">
                        {item.mobileNumber}
                      </div>
                    </div>

                    <div className="attribute-container part-id">
                      <div className="attribute" data-name="Role Name">
                        {item.isActive ? <span className="icon-checked"></span> : <span className="icon-NotActive"></span>}
                      </div>
                    </div>
                  </li>
                ))}
              </form>
            </ol>
          </div>
          <Pagination
            PageIndex={myResidents.pageIndex}
            PageSize={props.PassPageSize}
            TotalCount={myResidents.totalCount}
            TotalPages={myResidents.totalPages}
            HasPreviousPage={myResidents.hasPreviousPage}
            HasNextPage={myResidents.hasNextPage}
            actionType="Residents"
          />
        </div>
      ) : null}
    </>
  );
}
